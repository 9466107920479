import { FormField, TextInput } from "grommet";
import { messagesInscriptionSession } from "../../messages/messagesInscriptionSession";

import { FormFieldLabel } from "./FormFieldLabel";

export const DateFormField = (props) => {
    const {
        label,
        italicLabel,
        required,
        name,
        placeholder,
        birthdate,
        validation,
        ageMin,
        ageMax,
        ...rest
    } = props;

    function validateBirthDate(value: string) {
        if (value) {
            let date = new Date(value);
            let currentDate = new Date();
            let dateUtc = new Date(
                date.getUTCFullYear(),
                date.getUTCMonth(),
                date.getUTCDate()
            );
            let currentDateUtc = new Date(
                currentDate.getUTCFullYear(),
                currentDate.getUTCMonth(),
                currentDate.getUTCDate()
            );
            if (dateUtc.getTime() > currentDateUtc.getTime()) {
                return messagesInscriptionSession.BIRTHDATE_ERROR;
            }

            if (ageMin !== undefined || ageMax !== undefined) {
                let ageDiff = Date.now() - date.getTime();
                let ageDate = new Date(ageDiff);
                let age = Math.abs(ageDate.getUTCFullYear() - 1970);
                if (
                    (ageMin > 0 && age < ageMin) ||
                    (ageMax > 0 && age > ageMax)
                ) {
                    return messagesInscriptionSession.BIRTHDATE_ERROR;
                }
            }
        }
    }

    // Mantis 7504 : Limitation des dates possibles entre le 1er janvier 1900 et le 31 décembre 2100
    const regularValidation = required
        ? {
              regexp: new RegExp("^(19[0-9]{2}|20[0-9]{2}|2100)-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$"),
              message: "Date invalide",
          }
        : {
              regexp: new RegExp("^(?:(19[0-9]{2}|20[0-9]{2}|2100)-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]))?$"),
              message: "Date invalide",
          };

    return (
        <FormField
            label={
                <FormFieldLabel
                    label={
                        label + " " + messagesInscriptionSession.DATE_FORMAT_FR
                    }
                    required={required}
                    italicLabel={italicLabel}
                />
            }
            required={required}
            name={name}
            a11yTitle={
                label + required ? " (Obligatoire pour soumission)" : undefined
            }
            validate={birthdate ? validateBirthDate : regularValidation}
            htmlFor={name}
            {...rest}
        >
            <TextInput
                id={name}
                name={name}
                placeholder={placeholder}
                type="date"
            />
        </FormField>
    );
};
