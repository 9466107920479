export const formCodes =  {
    ENGAGEMENT_DRAPEAUX_MOIS: "engagementSousDrapeauxNbrMois",
    ENGAGEMENT_DRAPEAUX_ANNEE: "engagementSousDrapeauxNbrAnnees",
    NOM: "nom",
    PRENOM: "prenom",
    CIVILITE: "civilite",
    NOM_MARITAL: "nomMarital",
    AUTRES_PRENOMS: "autresPrenoms",
    ADRESSE_1: "adresse",
    ADRESSE_2: "compAdresse",
    CODE_POSTAL: "codePostal",
    COMMUNE: "commune",
    PAYS: "pays",
    DATE_NAISSANCE: "dateNaiss",
    LIEU_NAISSANCE: "lieuNaiss",
    NATIONALITE: "nationalite",
    NATIONALITE_EN_COURS: "nationaliteEnCours",
    PAYS_NAISSANCE: "paysNaiss",
    DEPARTEMENT_NAISSANCE: "depNaiss",
    EMAIL: "email",
    TELEPHONE: "telephone",
    ETAT_DIPLOME: "diplomeEtat",
    DIPLOME: "diplome",
    TELEPHONE_PRO: "texteTelpro",
    NOM_MERE: "nomMere",
    NOM_PERE: "nomPere",
    AFFECTATION_ADMIN: "texteAffectadm",
    AFFECTATION_ADMIN_2: "texteAffectadm2",
    ADRESSE_AFFECTATION: "texteAdraffect",
    ADRESSE_AFFECTATION_2: "texteAdraffect2",
    CODE_POSTAL_AFFECTATION: "texteCdpostal",
    COMMUNE_AFFECTATION: "texteCommune",
    PROFESSION_MERE: "professionMere",
    PROFESSION_PERE: "professionPere",
    AMENAGEMENT_DEMANDE: "amenagementDemande",
    AMENAGEMENT_DEMANDE_EXT: "amenagementDemandeExt",
    SPORTIF_HAUT_NIVEAU_ANNEE: "sportifHautNiveauNbrAnnee",
    TRAVAILLEUR_HANDICAPE_ANNEE: "travaileurHandicapeNbrAnnee",
    ANCIEN_SPORTIF_HAUT_NIVEAU: "ancienSportifHautNiveau",
    ANCIEN_TRAVAILLEUR_HANDICAPE: "ancienTravaileurHandicape",
    ADMINISTRATION_EMPLOI: "listeAdminemp",
    DIRECTION_AFFECTATION: "listeDiraffectpn",
    PAYS_AFFECTATION : "listePaysaffect",
    POSITION_ADMINISTRATIVE: "listePosadm",
    PERSONNE_HANDICAPEE: "personneHandicapee",
    AUCUN_LBL: "aucunLbl",
    DATE_LBL7: "dateLbl7",
    DATE_LBL8: "dateLbl8"
};